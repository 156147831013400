$(() => {
  jQuery.expr[':'].Contains = (a, i, m) => {
    return jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0
  }

  window.searchable = selector => {
    $(selector)
      .find('.search')
      .keyup(function () {
        const value = $(this).val()
        $('table.table-searchable tr').hide()
        const rows = $("table.table-searchable td:Contains('" + value + "')").parent()
        rows.show()
        if (!value) {
          $('table.table-searchable tr').show()
        }
      })
  }
})
