$(() => {
  if ($('body.orders.edit').length === 0) return

  const modal = $('#remote-modal')
  const enter = event => {
    if (event.key !== 'Enter') return

    event.preventDefault()
    Rails.fire(event.target.form, 'submit')
    modal.modal('hide')
  }
  $(document).on('keydown', '#remote-modal form', enter)

  const clearAmount = event => {
    event.preventDefault()
    const amountInput = $(event.target).parents('.input-group').find('.amount')
    amountInput.val('').trigger('change')
  }
  $(document).on('click', '#remote-modal .clear-amount', clearAmount)
})
