import 'flatpickr'
import 'flatpickr/dist/l10n/nl'

$(() => {
  $('.datepicker').flatpickr({
    locale: 'nl',
    dateFormat: 'd-m-Y',
    disableMobile: true
  })

  $('.datetimepicker').flatpickr({
    enableTime: true,
    time_24hr: true,
    locale: 'nl',
    dateFormat: 'd-m-Y H:i'
  })

  $('.datepicker-clear').click(function (event) {
    const datepicker = $(event.target).closest(':has(.datepicker)').find('.datepicker').flatpickr()
    datepicker.clear()
    return false
  })
})
