/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from 'jquery'
window.jQuery = $
window.$ = $

import '../src/javascripts/rails'
import '../src/javascripts/morphdom'
import '../src/javascripts/bootstrap'
import '../src/javascripts/modal'
import '../src/javascripts/autosubmit'
import '../src/javascripts/link'
import '../src/javascripts/order'
import '../src/javascripts/event'
import '../src/javascripts/datepicker'
import '../src/javascripts/select'
import '../src/javascripts/search'
import '../src/javascripts/editor'

import '../src/stylesheets/application/den_ham'
