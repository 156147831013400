$(() => {
  if ($('body.events').filter('.new, .create, .edit, .update').length === 0) return

  $(document).on('click', 'a.snippet-link', event => {
    event.preventDefault()
    const link = event.target
    const selector = link.dataset.selector
    const snippet = link.dataset.snippet
    const editor = document.querySelector(selector).editor
    editor.setSelectedRange(editor.getDocument().getLength() - 1)
    editor.insertLineBreak()
    editor.insertHTML(snippet)
  })
})
