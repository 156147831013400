import Trix from 'trix'
import '@rails/actiontext'

Trix.config.lang = {
  attachFiles: 'Bestanden bijvoegen',
  bold: 'Vet',
  bullets: 'Lijst',
  byte: 'Byte',
  bytes: 'Bytes',
  captionPlaceholder: 'Onderschrift toevoegen…',
  heading1: 'Titel',
  indent: 'Meer inspringen',
  italic: 'Cursief',
  link: 'Link',
  numbers: 'Opsomming',
  outdent: 'Minder inspringen',
  quote: 'Citaat',
  redo: 'Opnieuw toepassen',
  remove: 'Verwijderen',
  strike: 'Doorstrepen',
  undo: 'Ongedaan maken',
  unlink: 'Link verwijderen',
  url: 'URL',
  urlPlaceholder: 'URL opgeven…',
  GB: 'GB',
  KB: 'KB',
  MB: 'MB',
  PB: 'PB',
  TB: 'TB'
}

Trix.config.toolbar.getDefaultHTML = () => {
  const lang = Trix.config.lang
  return `
    <div class="mb-3">
      <div class="btn-group">
        <button type="button" class="btn btn-light" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1"><i class="fas fa-bold"></i></button>
        <button type="button" class="btn btn-light" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1"><i class="fas fa-italic"></i></button>
        <button type="button" class="btn btn-light" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1"><i class="fas fa-strikethrough"></i></button>
      </div>
      <div class="btn-group ml-3">
        <button type="button" class="btn btn-light" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1"><i class="fas fa-heading"></i></button>
        <button type="button" class="btn btn-light" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1"><i class="fas fa-list"></i></button>
        <button type="button" class="btn btn-light" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1"><i class="fas fa-list-ol"></i></button>
      </div>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>
  `
}
